import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getAllmenus } from '../../api/menuapi';

const Navbar = () => {
  const [menus, setMenus] = useState([])

  useEffect(()=>{
    getAllmenus()
    .then(data=>setMenus(data))
  },[])
  return (
    <>
      
      {/* <div className='col-md-8'>
            <Link className="navbar-brand text-white" to="/"><img src='./images/dakali-commented-logo2.jpeg' alt="..." /></Link>
          </div> */}
          
      <nav className="navbar navbar-expand-lg bg-light-subtle">


      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent"> 
                      
        <div className='col-md-3'>
           <Link className="navbar-brand" to="/"><img src='./images/dakali-commented-logo2.jpeg' alt="..." /></Link>
          
           </div>
        
           <div className='col-md-7'>
          {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
         
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 f3-3 fw-bold">
           {/* { menus && menus.map(menu=>{
                return <li className="nav-item p-3" key={menu._id} value={menu._id}>
                <Link className="acnav-link active" aria-current="page" to="/">{menu.menu_name}</Link>
           
              </li>
              })
              } */}
            
              <li className="nav-item p-3">
                <Link className="acnav-link active" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item p-2">
                <Link className="nav-link" to="/aboutus">About Us</Link>
              </li>
              <li className="nav-item p-2">
                <Link className="nav-link" to="/brands">Our Brands</Link>
              </li>
              <li className="nav-item p-2">
                <Link className="nav-link" to="/product">Our Products</Link>
              </li>
              <li className="nav-item p-2">
                <Link className="nav-link" to="/team">Our Team</Link>
              </li>
              
             
              <li className="nav-item p-2">
                <Link className="nav-link" to="/contact">Contact Us</Link>
              </li>
              {/* <li className="nav-item dropdown">
          <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </Link>
          <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="#">Action</Link></li>
            <li><Link className="dropdown-item" to="#">Another action</Link></li>
            <li><hr className="dropdown-divider"/></li>
            <li><Link className="dropdown-item" to="#">Something else here</Link></li>
          </ul>
          </li> */}

              {/* <li className="nav-item">
          <Link className="nav-link disabled" aria-disabled="true">Disabled</Link>
        </li> */}
            </ul>
            {/* <form className="d-flex" role="search">
              <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
              <button className="btn btn-outline-success" type="submit">Search</button>
            </form> */}

            
          </div>
          </div>
          <div className='col-md-1'>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 f3-3 fw-bold">
              {/* <li className="ms-4"><Link className="text-body-secondary" to="https://www.twitter.com/login">
                        <i className='bi bi-twitter'></i></Link></li> */}
              {/* <li className="nav-item p-3"><Link className="text-body-secondary" to="https://np.linkedin.com/in/kiran-shrestha-87016134">
                <i className='bi bi-instagram'></i></Link></li> */}
                <li className="nav-item p-3"><Link className="text-body-secondary" to="https://www.facebook.com/dakali.ind/">
                <i className='bi bi-facebook'></i></Link></li>
                <li className="nav-item p-3"><Link className="text-body-secondary" to="https://np.linkedin.com/in/kiran-shrestha-87016134">
                <i className="bi bi-linkedin"></i></Link></li>
            </ul>
          </div>
          
        
      </nav>

    </>
  )
}

export default Navbar