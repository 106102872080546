import { API } from "../config";

export const getAllTeams = () =>{
    return fetch (`${API}/getallteams`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

export const addTeam = (team, token) => {
    return fetch(`${API}/addteam`,{
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        body:team
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to get team details
export const getTeamDetails = (id) => {
    return fetch(`${API}/teamdetails/${id}`)
    .then(res=>res.json())
    .catch(err=>console.log(err))

}

//to update team
export const updateTeam = (id, team, token) => {
    return fetch(`${API}/updateteam/${id}`,{
        method: "PUT",
        headers:{
           
            "Authorization" : `Bearer ${token}`
        },
        body: team
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}
//to delete team
export const deleteTeam = (id, token) => {
    return fetch(`${API}/deleteteam/${id}`,{
        method: "DELETE",
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`
        }
})
.then(res=>res.json())
.catch(err=>console.log(err))
}