import { API } from "../config";

export const getAllBrands = () =>{
    return fetch (`${API}/getallbrands`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

export const addBrand = (brand, token) => {
    return fetch(`${API}/addbrand`,{
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        body:brand
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to get brand details
export const getBrandDetails = (id) => {
    return fetch(`${API}/branddetails/${id}`)
    .then(res=>res.json())
    .catch(err=>console.log(err))

}

//to update brand
export const updateBrand = (id, brand, token) => {
    return fetch(`${API}/updatebrand/${id}`,{
        method: "PUT",
        headers:{
           
            "Authorization" : `Bearer ${token}`
        },
        body: brand
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to delete brand
export const deleteBrand = (id, token) => {
    return fetch(`${API}/deletebrand/${id}`,{
        method: "DELETE",
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`
        }
})
.then(res=>res.json())
.catch(err=>console.log(err))
}


