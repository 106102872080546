import React, { useEffect, useRef, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { getProductbygenericDetails, updateProductbygeneric } from '../../../../api/productbygenericapi';
import { Link, useParams } from 'react-router-dom';
import { API } from '../../../../config';

const UpdateProductbygeneric = () => {
    const [productbygeneric, setProductbygeneric] = useState({
        productbygeneric_name: '',
        productbygeneric_usage: '',
        productbygeneric_alternativename: '',
        productbygeneric_source: '',
        productbygeneric_image: '',
        productbygeneric_description: '',
        error:'',
        success:false,
        formdata: new FormData
    })
    let file_ref = useRef()

    const {id} = useParams()

    // const {token} = isAuthenticated()
    const {productbygeneric_image, productbygeneric_name,productbygeneric_usage,productbygeneric_alternativename,productbygeneric_source,productbygeneric_description,error,success,formdata} = productbygeneric

    useEffect(()=>{
        getProductbygenericDetails(id)
        .then(data=>{
            if(data.error){
                console.log(data.error)
            }
            else{
                //setAbout(data)
                setProductbygeneric({...productbygeneric, ...data, formdata: new FormData})
            }
        })
        
    },[success])


    const handleChange = name => e => {
        let value
        if(name === 'productbygeneric_image'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
            setProductbygeneric({...productbygeneric, [name]: value})
        }
        formdata.set(name, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // addCarousel(formdata, token)
        updateProductbygeneric(id, formdata)
        .then(data=>{
            if(data.error){
                setProductbygeneric({...productbygeneric, error:data.error, success: false})
            }
            else{
                setProductbygeneric({success:true,  error:''})
                
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Productbygeneric Updated Successfully.</div>
        }
    }

  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar productbygeneric/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Update New Productbygeneric.</h2>
                {showError()}
                {showSuccess()}
                <div className='row'>
                    <div className='col-6'>
                    <img src={`${API}/${productbygeneric_image}`} className='w-100'/>
                    </div>
                    <div className='col-6'>
                    <form>
                    <label htmlFor='productbygeneric_name'>Productbygeneric Name</label>
                    <input type='text' className='form-control mb-1' id='productbygeneric-name' onChange={handleChange('productbygeneric_name')} value={productbygeneric_name}/>
                    <label htmlFor='productbygeneric_usage'>Usage</label>
                    <input type='text' className='form-control mb-1' id='productbygeneric-usage' onChange={handleChange('productbygeneric_usage')} value={productbygeneric_usage}/>
                    <label htmlFor='productbygeneric_alternativename'>Alternative Name</label>
                    <input type='text' className='form-control mb-1' id='productbygeneric-alternativename' onChange={handleChange('productbygeneric_alternativename')} value={productbygeneric_alternativename}/>
                    <label htmlFor='productbygeneric_alternativename'>Source/Country</label>
                    <input type='text' className='form-control mb-1' id='productbygeneric-source' onChange={handleChange('productbygeneric_source')} value={productbygeneric_source}/>
                    <label htmlFor='productbygeneric_source'>Productbygeneric Image</label>
                    <input type='file' className='form-control mb-1' id='productbygeneric_image' onChange={handleChange('productbygeneric_image')} ref={file_ref}/>
                    <label htmlFor='productbygeneric_description'>Productbygeneric Description</label>
                    <textarea className='form-control mb-1' rows={5} id='productbygeneric_description' onChange={handleChange('productbygeneric_description')} value={productbygeneric_description}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Update Productbygeneric</button>

                    <Link to = '/admin/productbygeneric'>Go back</Link>
                </form>
                    </div>
                </div>
                        
                
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default UpdateProductbygeneric