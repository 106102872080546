import { API } from "../config"

//signin
export const signIn = (email, password) => {
    return fetch(`${API}/signin`,{
        method: "POST",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify({email, password})
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to keep signed in
export const authenticate = (data)=>{
    localStorage.setItem('jwt',JSON.stringify(data))
}

//to check if logged in
export const  isAuthenticated = () => {
    return localStorage.getItem('jwt') ? JSON.parse(localStorage.getItem('jwt')) : false
}