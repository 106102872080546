
import React, { useEffect, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { Link, useParams } from 'react-router-dom';
import { getCustomerDetails, updateCustomer} from '../../../../api/customerapi';



const UpdateCustomer = () => {
    let [customer_name, setCustomerName] = useState('')
    let [customer_email, setCustomerEmail] = useState('')
    let [customer_description, setCustomerDescription] = useState('')
    // let {token} = isAuthenticted()

    let [error, setError] = useState('')
    let [success, setSuccess] = useState(false)

   let {id} = useParams()

   useEffect(()=>{
    getCustomerDetails(id).then(data=>setCustomerName(data.customer_name))
    getCustomerDetails(id).then(data=>setCustomerEmail(data.customer_email))
    getCustomerDetails(id).then(data=>setCustomerDescription(data.customer_description))
   },[])

    const handleSubmit = (e) => {
        e.preventDefault()
        // addMenu(customer_name, token)
        updateCustomer(id, customer_name,customer_email,customer_description,)
        .then(data=>{
            if(data.error){
                setError(data.error)
                setSuccess(false)
            }
            else{
                setSuccess(true)
                setError('')
                // setCustomerName('')
                // setCustomerEmail('')
                // setCustomerDescription('')
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Customer Updated Successfully.</div>
        }
    }
    
    return (
        <>
{/* <Navbar/> */}
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminSidebar customer />
                    </div>
                    <div className='col-md-9 p-5'>
                        <h2>Update Customer</h2>
                        {showSuccess()}
                        {showError()}
                        <form className='p-5 mt-3 shadow-lg w-50'>
                            <label htmlFor='customer_name'>Customer Name</label>
                            <input type='text' id='customer_name' className='form-control' onChange={e=>setCustomerName(e.target.value)} value={customer_name}/>
                            <label htmlFor='customer_email'>Customer Email</label>
                            <input type='email' id='customer_email' className='form-control' onChange={e=>setCustomerEmail(e.target.value)} value={customer_email}/>
                            <label htmlFor='customer_description'>Customer Description</label>
                            <textarea id='customer_description' className='form-control'rows={5} onChange={e=>setCustomerDescription(e.target.value)} value={customer_description}></textarea>
                            
                            <button className='btn btn-warning w-100 mt-3' onClick={handleSubmit}>Update Customer</button>

                            <Link to = '/admin/customer'>Go back</Link>
                        </form>
                    </div>
                </div>
                <AdminFooter />
            </div>

        </>
    )
}




export default UpdateCustomer