
import React, { useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { Link } from 'react-router-dom';
import { addContact } from '../../../../api/contactapi';


const AddContact = () => {
    let [contact_title, setContactTitle] = useState('')
    let [contact_address, setContactAddress] = useState('')
    let [contact_email, setContactEmail] = useState('')
    let [contact_phone, setContactPhone] = useState('')
    // let {token} = isAuthenticted()
    let [error, setError] = useState('')
    let [success, setSuccess] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        // addMenu(contact_name, token)
        addContact(contact_title, contact_address, contact_email, contact_phone)
        .then(data=>{
            if(data.error){
                setError(data.error)
                // setSuccess(false)
            }
            else{
                setSuccess(true)
                // setError('')
                // setCustomerName('')
                // setCustomerEmail('')
                // setCustomerDescription('')
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Contact Added Successfully.</div>
        }
    }
    
    return (
        <>
{/* <Navbar/> */}
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminSidebar contactus />
                    </div>
                    <div className='col-md-9 p-5'>
                        <h2>Add Contact</h2>
                        {showSuccess()}
                        {showError()}
                        <form className='p-5 mt-3 shadow-lg w-50'>
                            <label htmlFor='contact_name'>Contact Title</label>
                            <input type='text' id='contact_title' className='form-control' onChange={e=>setContactTitle(e.target.value)} value={contact_title}/>
                            <label htmlFor='contact_title'>Contact Address</label>
                            <input type='text' id='contact_address' className='form-control' onChange={e=>setContactAddress(e.target.value)} value={contact_address}/>
                            <label htmlFor='contact_address'>Contact Email</label>
                            <input type='email' id='contact_email' className='form-control' onChange={e=>setContactEmail(e.target.value)} value={contact_email}/>
                            <label htmlFor='contact_phone'>Contact Phone</label>
                            
                            <input type='number' id='contact_phone' className='form-control' onChange={e=>setContactPhone(e.target.value)} value={contact_phone}/>
                            
                            <button className='btn btn-warning w-100 mt-3' onClick={handleSubmit}>Add New Contact</button>

                            <Link to = '/admin/contact'>Go back</Link>
                        </form>
                    </div>
                </div>
                <AdminFooter />
            </div>

        </>
    )
}




export default AddContact