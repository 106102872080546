import  { useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import { authenticate, isAuthenticated, signIn } from '../../../api/userapi'
//import jwt from 'jsonwebtoken';

const Login = () => {
    let [email, setEmail] = useState('')
    let [password, setPassword] = useState('')
    let [error, setError] = useState('')
    let [success, setSuccess] = useState('false')

    let navigate = useNavigate()

const handleSignin = (e) => {
    e.preventDefault()

    
    signIn(email, password)
    .then(data=>{
        if(data.error){
            setError(data.error)
        }
        else{
            authenticate(data)
            setSuccess(true)
        }
    })

}
const showError= () => {
    if(error){
        return <div className='alert alert-danger'>{error}</div>
    }
}
const redirect = () => {
    if(success){
        // navigate('/admin/dashboard')
        if(isAuthenticated() && isAuthenticated().user.role === 1){
            navigate('/admin/dashboard')
        }
         else{
             navigate('/admin/login')
         }
    }
}

    return (
        <>
            {showError()}
            {redirect()}
            <div className='row'>
                <div className='col-md-6 p-5 m-auto shadow-lg my-5' style={{width:'50%'}}>
                    <main className="form-signin w-75 m-auto">
                        <form>
                            <div className='text-center'>
                                <img className="mb-4" src="./category-images/3.jpeg" alt="" width="72" height="57" />
                            </div>
                            <h1 className="h3 mb-3 fw-normal">Admin</h1>
                            
                            <div className="form-floating">
                                <input type="username" className="form-control" id="floatingInput" placeholder="username" onChange={e=>setEmail(e.target.value)}/>
                                <label htmlFor="floatingInput">Username</label>
                            </div>
                            <div className="form-floating">
                                <input type="password" className="form-control" id="floatingPassword" placeholder="Password" onChange={e=>setPassword(e.target.value)}/>
                                <label htmlFor="floatingPassword">Password</label>
                            </div>

                            <div className="form-check text-start my-3">
                                <input className="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Remember me
                                </label>
                            </div>
                            <button className="btn btn-primary w-100 py-2" type="submit" onClick={handleSignin}>Sign in</button>
                            <div className='d-flex justify-content-between'>
                                <span>Do not have an account?<Link to='/register'>Register</Link></span>
                                <span><Link to='/forgetpassword'>Forget Password</Link></span>
                            </div>
                            <p className="mt-5 mb-3 text-body-secondary text-center">&copy; 2017–2024</p>
                        </form>
                    </main>
                </div>
            </div>
        </>
    )
}
export default Login