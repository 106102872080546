import { API } from "../config";

export const getAllProductbyindustries = () =>{
    return fetch (`${API}/getallproductbyindustries`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

export const addProductbyindustry = (productbyindustry, token) => {
    return fetch(`${API}/addproductbyindustry`,{
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        body:productbyindustry
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to get productbyindustry details
export const getProductbyindustryDetails = id => {
    return fetch(`${API}/productbyindustrydetails/${id}`)
    .then(res=>res.json())
    .catch(err=>console.log(err))

}

//to update productbyindustry
export const updateProductbyindustry = (id, productbyindustry, token) => {
    return fetch(`${API}/updateproductbyindustry/${id}`,{
        method: "PUT",
        headers:{
           
            "Authorization" : `Bearer ${token}`
        },
        body: productbyindustry
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}
//to delete productbyindustry
export const deleteProductbyindustry = (id, token) => {
    return fetch(`${API}/deleteproductbyindustry/${id}`,{
        method: "DELETE",
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`
        }
})
.then(res=>res.json())
.catch(err=>console.log(err))
}
