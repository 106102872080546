import React from 'react'
import Navbar from './layouts/Navbar'
import Footer from './layouts/Footer'
const Products = () => {
    return (
        <>
            <Navbar />
            <div className='mt-3 pt-1'>
                {/* <img src='./images/drum-images.jpg' alt='..' style={{width: '100%',height: '300px'}}/> */}
                <img src='./images/Our_Products_main.png' alt='..' style={{ width: '100%', height: '300px' }} />

                {/* <center><h1>Products</h1></center> */}
            </div>

            <div className='container p-0'>
                <div className="row p-4 m-2">
                    <div className="col-lg-6 p-1">
                        <center><img src='./images/Productsby_industry4.png' className='rounded-circle' alt='...' /></center>
                            <nav className="navbar">
                                <form className="fr" role="search">
                                    <input className="form-control me-2" type="search" placeholder="Type industry here" aria-label="Search" />
                                    {/* <button className="btn btn-outline-secondary" type="submit">Go</button> */}
                                </form>
                            </nav>
                    </div>
            <div className="col-lg-6 p-1">
                <center><img src='./images/Productsby_generic1.png' className='rounded-circle' alt='...' /></center>
                    {/* <svg className="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)" /></svg>
                    <h1 className="fw-normal">Heading</h1>
                    <p>And lastly this, the third column of representative placeholder content.</p>
                    <p><Link className="btn btn-secondary" to="#">View details &raquo;</Link></p> */}
                    <nav className="navbar">
                        <form className="fr" role="search">
                        <input className="form-control me-2" type="search" placeholder="Type generic name here" aria-label="Search" />
                        {/* <button className="btn btn-outline-secondary" type="submit">Go</button> */}
                        </form>
                    </nav>
            </div>
                </div>
            </div>
            <Footer />

        </>
    )
}

export default Products