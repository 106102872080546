import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { deleteCustomer, getAllcustomers } from '../../../../api/customerapi'
// import { API } from '../../../../config'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

const AdminCustomer = () => {
    const [customers, setCustomers] = useState([])

    const [success, setSuccess] = useState(false)

    useEffect(() => {
        getAllcustomers()
            .then(data => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    setCustomers(data)
                }
                        })
    }, [success])

    const handleDelete = id => e => {
        e.preventDefault()
        setSuccess(false)
        swal("Delete Customer","Are you sure you want to delete this customer?", {
            buttons: {
              Cancel: "Cancel",
               Delete: "Delete"
            },
            icon:"warning"
          })
          .then((value) => {
            switch (value) {
           
             
           
              case "Cancel":
                swal("Cancel", "Delete Canceled!", "warning");
                break;
             case "Delete":
                // deleteCustomer(id, token)
                deleteCustomer(id)
                .then(data=>{
                    if(data.error){
                        swal("Error!", data.error, "error");
                    }
                    else{
                        setSuccess(true)
                        swal("Success !", data.msg, "success");
                    }
                })  
                .catch(
                    swal("Something went wrong!")
                ) 
                break
           
              default:
                swal("Something went wrong!");
            }
          });
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminSidebar customers />
                    </div>
                    <div className='col-md-9 p-5'>
                        <h2>Customers</h2>
                        <table className='table text-center table-hover table-bordered'>
                            <thead className='table-dark'>
                                <tr>
                                    <td>S.No.</td>
                                    <td>Customer Name</td>
                                    <td>Customer Email</td>
                                    <td>Customer Feedback</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customers && customers.map((customer,i)=>{
                                        return <tr key={customer._id}>
                                                    <td>{i+1}</td>
                                                    <td>{customer.customer_name}</td>
                                                    <td>
                                                    {customer.customer_email}
                                                    </td>
                                                    <td>{customer.customer_description}</td>
                                                    <td>
                                                        <div className='btn btn-group'>
                                                            <Link to={`/admin/customer/update/${customer._id}`} className='btn btn-warning'>Update</Link>
                                                            <button className='btn btn-danger' onClick={handleDelete(customer._id)}>Remove</button>
                                                        </div>
                                                    </td>

                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                       <button className='btn btn-info'><Link to ='/admin/customer/add'>Add New Customer</Link></button>
                    </div>
                </div>
                <AdminFooter />
            </div>

        </>
    )
}

export default AdminCustomer