import React, { useEffect, useRef, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { getProductbyindustryDetails, updateProductbyindustry } from '../../../../api/productbyindustryapi';
import { useParams } from 'react-router-dom';
import { API } from '../../../../config';
import { Link } from 'react-router-dom';


const UpdateProductbyindustry = () => {
    const [productbyindustry, setProductbyindustry] = useState({
        productbyindustry_title: '',
        productbyindustry_image: '',
        productbyindustry_description: '',
        error:'',
        success:false,
        formdata: new FormData
    })
    let file_ref = useRef()

    const {id} = useParams()
    // const {token} = isAuthenticated()
    const {productbyindustry_image, productbyindustry_title,productbyindustry_description,error,success,formdata} = productbyindustry

    useEffect(()=>{
        getProductbyindustryDetails(id)
        .then(data=>{
            if(data.error){
                console.log(data.error)
            }
            else{
                //setAbout(data)
                setProductbyindustry({...productbyindustry, ...data, formdata:new FormData})
            }
        })
        
    },[success])

    const handleChange = title => e => {
        let value
        if(title === 'productbyindustry_image'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
            setProductbyindustry({...productbyindustry, [title]: value})
        }
        
        formdata.set(title, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // updateProductbyindustryProductbyindustry(formdata, token)
        updateProductbyindustry(id, formdata)
        .then(data=>{
            if(data.error){
                setProductbyindustry({...productbyindustry, error:data.error, success: false})
            }
            else{
                setProductbyindustry({success:true,  error:''})
               
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Productbyindustry Updated Successfully.</div>
        }
    }

  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar productbyindustry/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Update New Productbyindustry.</h2>
                {showError()}
                {showSuccess()}
                <div className='row'>
                    <div className='col-6'>
                    <img src={`${API}/${productbyindustry_image}`} className='w-100' alt = '{`${API}/${productbyindustry_image}`}'/>
                    </div>
                    <div className='col-6'>
                    <form>
                    <label htmlFor='productbyindustry_title'>Productbyindustry title</label>
                    <input type='text' className='form-control mb-1' id='productbyindustry_title' onChange={handleChange('productbyindustry_title')} value={productbyindustry_title}/>
                    <label htmlFor='productbyindustry_image'>Productbyindustry Image</label>
                    <input type='file' className='form-control mb-1' id='productbyindustry_image' onChange={handleChange('productbyindustry_image')}/>
                    <label htmlFor='productbyindustry_description'>Productbyindustry Description</label>
                    <textarea className='form-control mb-1' rows={5} id='productbyindustry_description' onChange={handleChange('productbyindustry_description')} value={productbyindustry_description}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Update Productbyindustry</button>
                    <Link to = '/admin/productbyindustry'>Go back</Link>
                </form>
                    </div>
                </div>
                        
                
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default UpdateProductbyindustry