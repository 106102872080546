import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { API } from '../../../../config'
import { Link } from 'react-router-dom'
import { deleteAbout, getAllAbouts } from '../../../../api/aboutapi'
import swal from 'sweetalert'

const AdminAbout = () => {
    const [abouts, setAbouts] = useState([])

    const [success, setSuccess] = useState(false)

    useEffect(() => {
        getAllAbouts()
            .then(data => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    setAbouts(data)
                }
                        })
    }, [success])
    const handleDelete = id => e => {
        e.preventDefault()
        setSuccess(false)
        swal("Delete About","Are you sure you want to delete this about?", {
            buttons: {
              Cancel: "Cancel",
               Delete: "Delete"
            },
            icon:"warning"
          })
          .then((value) => {
            switch (value) {
           
             
           
              case "Cancel":
                swal("Cancel", "Delete Canceled!", "warning");
                break;
             case "Delete":
                // deleteAbout(id, token)
                deleteAbout(id)
                .then(data=>{
                    if(data.error){
                        swal("Error!", data.error, "error");
                    }
                    else{
                        setSuccess(true)
                        swal("Success !", data.msg, "success");
                    }
                })  
                .catch(
                    swal("Something went wrong!")
                ) 
                break
           
              default:
                swal("Something went wrong!");
            }
          });
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminSidebar aboutus />
                    </div>
                    <div className='col-md-9 p-5'>
                        <h2>Abouts</h2>
                        <table className='table text-center table-hover table-bordered'>
                            <thead className='table-dark'>
                                <tr>
                                    <td>S.No.</td>
                                    <td>About Title</td>
                                    <td>About Image</td>
                                    <td>About Description</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    abouts && abouts.map((about,i)=>{
                                        return <tr key={about._id}>
                                                    <td>{i+1}</td>
                                                    <td>{about.about_title}</td>
                                                    <td>
                                                        <img src={`${API}/${about.about_image}`} alt = {about.about_image} style={{height:"80px",width:'150px'}}/>
                                                    </td>
                                                    <td>{about.about_description}</td>
                                                    <td>
                                                        <div className='btn btn-group'>
                                                            <Link to={`/admin/about/update/${about._id}`} className='btn btn-warning'>Update</Link>
                                                            <button className='btn btn-danger' onClick={handleDelete(about._id)}>Remove</button>
                                                        </div>
                                                    </td>

                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        <Link to ='/admin/about/add'>Add New About</Link>
                    </div>
                </div>
                <AdminFooter />
            </div>

        </>
    )
}

export default AdminAbout