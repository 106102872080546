import { API } from "../config";

export const getAllContacts = () =>{
    return fetch (`${API}/getallcontacts`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

export const addContact = (contact_title, contact_address, contact_email, contact_phone, token) => {
    return fetch(`${API}/addcontact`,{
        method: "POST",
        headers: {
            "Content-Type":"application/json",
            "Authorization" : `Bearer ${token}`
        },
        body: JSON.stringify({contact_title, contact_address, contact_email, contact_phone})
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to get contact details
export const getContactDetails = (id) => {
    return fetch(`${API}/contactdetails/${id}`)
    .then(res=>res.json())
    .catch(err=>console.log(err))

}
//to update contact
export const updateContact = (id, contact_title, contact_address, contact_email, contact_phone,token ) => {
    return fetch(`${API}/updatecontact/${id}`,{
        method: "PUT",
        headers: {
            "Content-Type":"application/json",
            "Authorization" : `Bearer ${token}`
        },
        body: JSON.stringify({contact_title, contact_address, contact_email, contact_phone})
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to delete contact
export const deleteContact = (id, token) => {
    return fetch(`${API}/deletecontact/${id}`,{
        method: "DELETE",
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`
        }
})
.then(res=>res.json())
.catch(err=>console.log(err))
}

