import React, { useEffect, useRef, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { addBrand } from '../../../../api/brandapi';

const AddBrand = () => {
    const [brand, setBrand] = useState({
        brand_name: '',
        brand_logo: '',
        brand_link: '',
        error:'',
        success:false,
        formdata: ''
    })


    let file_ref = useRef()
    // const {token} = isAuthenticated()
    const {brand_name,brand_link,error,success,formdata} = brand

    useEffect(()=>{
        setBrand({...brand, formdata: new FormData})
    },[])

    const handleChange = name => e => {
        let value
        if(name === 'brand_logo'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
        }
        setBrand({...brand, [name]: value})
        formdata.set(name, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // addBrand(formdata, token)
        addBrand(formdata)
        .then(data=>{
            if(data.error){
                setBrand({...brand, error:data.error, success: false})
            }
            else{
                setBrand({success:true, brand_name: '', brand_link: '', formdata: new FormData, error:''})
                file_ref.current.value = ''
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Brand Added Successfully.</div>
        }
    }

  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar brands/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Add New Brand.</h2>
                {showError()}
                {showSuccess()}
                        
                <form>
                    <label htmlFor='brand_name'>Brand Name</label>
                    <input type='text' className='form-control mb-1' id='brand_name' onChange={handleChange('brand_name')} value={brand_name}/>
                    <label htmlFor='brand_logo'>Brand Image</label>
                    <input type='file' className='form-control mb-1' id='brand_logo' onChange={handleChange('brand_logo')} ref={file_ref}/>
                    <label htmlFor='brand_link'>Brand Description</label>
                    <textarea className='form-control mb-1' rows={5} id='brand_link' onChange={handleChange('brand_link')} value={brand_link}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Add Brand</button>
                </form>
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default AddBrand