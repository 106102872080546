import { API } from "../config";

export const getAllcustomers = () =>{
    return fetch (`${API}/getallcustomers`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}
//to add customer
export const addCustomer = (customer_name, customer_email, customer_description, token) => {
    return fetch(`${API}/addcustomer`,{
        method: "POST",
        headers: {
            "Content-Type":"application/json",
            "Authorization" : `Bearer ${token}`
        },
        body: JSON.stringify({customer_name, customer_email, customer_description})
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}
//to get customer details
export const getCustomerDetails = (id) => {
    return fetch(`${API}/customerdetails/${id}`)
    .then(res=>res.json())
    .catch(err=>console.log(err))

}
//to update customer
export const updateCustomer = (id, customer_name, customer_email, customer_description,token ) => {
    return fetch(`${API}/updatecustomer/${id}`,{
        method: "PUT",
        headers: {
            "Content-Type":"application/json",
            "Authorization" : `Bearer ${token}`
        },
        body: JSON.stringify({customer_name, customer_email, customer_description})
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to delete customer
export const deleteCustomer = (id) => {
    return fetch(`${API}/deletecustomer/${id}`,{
        method: "DELETE",
        headers: {
            "Content-Type":"application/json",
            // Authorization: `Bearer ${token}`
        }
})
.then(res=>res.json())
.catch(err=>console.log(err))
}
