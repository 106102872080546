import React, { useEffect, useRef, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { addProductbygeneric } from '../../../../api/productbygenericapi';

const AddProductbygeneric = () => {
    const [productbygeneric, setProductbygeneric] = useState({
        productbygeneric_name: '',
        productbygeneric_usage: '',
        productbygeneric_alternativename: '',
        productbygeneric_source: '',
        productbygeneric_image: '',
        productbygeneric_description: '',
        error:'',
        success:false,
        formdata: ''
    })
    let file_ref = useRef()

    // const {token} = isAuthenticated()
    const {productbygeneric_name,productbygeneric_usage,productbygeneric_alternativename,productbygeneric_source,productbygeneric_description,error,success,formdata} = productbygeneric

    useEffect(()=>{
        setProductbygeneric({...productbygeneric, formdata: new FormData})
    },[])

    const handleChange = name => e => {
        let value
        if(name === 'productbygeneric_image'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
        }
        setProductbygeneric({...productbygeneric, [name]: value})
        formdata.set(name, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // addCarousel(formdata, token)
        addProductbygeneric(formdata)
        .then(data=>{
            if(data.error){
                setProductbygeneric({...productbygeneric, error:data.error,success: false})
            }
            else{
                setProductbygeneric({success:true, productbygeneric_name: '', productbygeneric_usage: '',productbygeneric_alternativename: '',productbygeneric_source: '',productbygeneric_description: '', formdata: new FormData, error:''})
                file_ref.current.value = ''
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Productbygeneric Added Successfully.</div>
        }
    }

  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar productbygeneric/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Add New Productbygeneric.</h2>
                {showError()}
                {showSuccess()}
                        
                <form>
                    <label htmlFor='productbygeneric_name'>Productbygeneric Name</label>
                    <input type='text' className='form-control mb-1' id='productbygeneric-name' onChange={handleChange('productbygeneric_name')} value={productbygeneric_name}/>
                    <label htmlFor='productbygeneric_usage'>Productbygeneric Usage</label>
                    <input type='text' className='form-control mb-1' id='productbygeneric_usage' onChange={handleChange('productbygeneric_usage')} value={productbygeneric_usage}/>
                    <label htmlFor='productbygeneric_alternativename'>Productbygeneric Alternative Name</label>
                    <input type='text' className='form-control mb-1' id='productbygeneric_alternativename' onChange={handleChange('productbygeneric_alternativename')} value={productbygeneric_alternativename}/>
                    <label htmlFor='productbygeneric_source'>Productbygeneric Source/Country</label>
                    <input type='text' className='form-control mb-1' id='productbygeneric_source' onChange={handleChange('productbygeneric_source')} value={productbygeneric_source}/>
                    <label htmlFor='productbygeneric_image'>Productbygeneric Image</label>
                    <input type='file' className='form-control mb-1' id='productbygeneric_image' onChange={handleChange('productbygeneric_image')} ref={file_ref}/>
                    <label htmlFor='productbygeneric_description'>Productbygeneric Description</label>
                    <textarea className='form-control mb-1' rows={5} id='productbygeneric_description' onChange={handleChange('productbygeneric_description')} value={productbygeneric_description}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Add Productbygeneric</button>
                </form>
            </div>
        </div>
        <AdminFooter/>
    </div>
    </>
  )
}

export default AddProductbygeneric