import { API } from "../config";

export const getAllProductbygenerics = () =>{
    return fetch (`${API}/getallproductbygenerics`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

export const addProductbygeneric = (productbygeneric, token) => {
    return fetch(`${API}/addproductbygeneric`,{
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        body:productbygeneric
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to get productbygeneric details
export const getProductbygenericDetails = id => {
    return fetch(`${API}/productbygenericdetails/${id}`)
    .then(res=>res.json())
    .catch(err=>console.log(err))

}

//to update productbygeneric
export const updateProductbygeneric = (id, productbygeneric, token) => {
    return fetch(`${API}/updateproductbygeneric/${id}`,{
        method: "PUT",
        headers:{
           
            "Authorization" : `Bearer ${token}`
        },
        body: productbygeneric
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to delete productbygeneric
export const deleteProductbygeneric = (id, token) => {
    return fetch(`${API}/deleteproductbygeneric/${id}`,{
        method: "DELETE",
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`
        }
})
.then(res=>res.json())
.catch(err=>console.log(err))
}