import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './components/pages/Home'
import Aboutus from './components/pages/Aboutus'
import Team from './components/pages/Team'
import Contactus from './components/pages/Contactus'
import Products from './components/Products'
import Brands from './components/Brands'
import AdminDashboard from './components/pages/Admin/AdminDashboard'
import AdminMenu from './components/pages/Admin/AdminMenu'
import AddMenu from './components/pages/Admin/AddMenu'
import UpdateMenu from './components/pages/Admin/UpdateMenu'
import AdminCarousel from './components/pages/Admin/Carousel/AdminCarousel'
import AddCarousel from './components/pages/Admin/Carousel/AddCarousel'
import AdminBrand from './components/pages/Admin/Brands/AdminBrand'
import AddBrand from './components/pages/Admin/Brands/AddBrand'
import AdminProductbygeneric from './components/pages/Admin/Productbygeneric/AdminProductbygeneric'
import AddProductbygeneric from './components/pages/Admin/Productbygeneric/AddProductbygeneric'
import AdminCustomer from './components/pages/Admin/Customer/AdminCustomer'
import AddCustomer from './components/pages/Admin/Customer/AddCustomer'
import AdminProductbyindustry from './components/pages/Admin/Productbyindustry/AdminProductbyindustry'
import AddProductbyindustry from './components/pages/Admin/Productbyindustry/AddProductbyindustry'
import AdminTeam from './components/pages/Admin/Team/Adminteam'
import AddTeam from './components/pages/Admin/Team/Addteam'
import AdminAbout from './components/pages/Admin/About/AdminAbout'
import AddAbout from './components/pages/Admin/About/AddAbout'
import AdminContact from './components/pages/Admin/Contact/AdminContact'
import AddContact from './components/pages/Admin/Contact/AddContact'
import UpdateCustomer from './components/pages/Admin/Customer/UpdateCustomer'
import UpdateAbout from './components/pages/Admin/About/UpdateAbout'
import UpdateTeam from './components/pages/Admin/Team/UpdateTeam'
import UpdateProductbyindustry from './components/pages/Admin/Productbyindustry/UpdateProductbyindustry'
import UpdateProductbygeneric from './components/pages/Admin/Productbygeneric/UpdateProductbygeneric'
import UpdateCarousel from './components/pages/Admin/Carousel/UpdateCarousel'
import UpdateBrand from './components/pages/Admin/Brands/UpdateBrand'
import UpdateContact from './components/pages/Admin/Contact/UpdateContact'
import Login from './components/pages/Admin/Login'


const Myroutes = () => {
  return (
    <BrowserRouter>
    <Routes>
        <Route path='/' element = {<Home/>}/>
        <Route path='/aboutus' element = {<Aboutus/>}/>
        <Route path='/team' element = {<Team/>}/>
        <Route path='/product' element = {<Products/>}/>
        <Route path='/contact' element = {<Contactus/>}/>
        <Route path='/brands' element = {<Brands/>}/>




        <Route path='/admin/login' element = {<Login/>}/>

        <Route path='/admin/dashboard' element = {<AdminDashboard/>}/>
        <Route path='/admin/menu' element = {<AdminMenu/>}/>
        <Route path='/admin/menu/add' element = {<AddMenu/>}/>
        <Route path='/admin/menu/update/:id' element = {<UpdateMenu/>}/>

        <Route path='/admin/carousel' element = {<AdminCarousel/>}/>
        <Route path='/admin/carousel/add' element = {<AddCarousel/>}/>
        <Route path='/admin/carousel/update/:id' element = {<UpdateCarousel/>}/>

        <Route path='/admin/about' element = {<AdminAbout/>}/>
        <Route path='/admin/about/add' element = {<AddAbout/>}/>
        <Route path='/admin/about/update/:id' element = {<UpdateAbout/>}/>

        <Route path='/admin/brand' element = {<AdminBrand/>}/>
        <Route path='/admin/brand/add' element = {<AddBrand/>}/>
        <Route path='/admin/brand/update/:id' element = {<UpdateBrand/>}/>

        <Route path='/admin/productbygeneric' element = {<AdminProductbygeneric/>}/>
        <Route path='/admin/productbygeneric/add' element = {<AddProductbygeneric/>}/>
        <Route path='/admin/productbygeneric/update/:id' element = {<UpdateProductbygeneric/>}/>

        <Route path='/admin/productbyindustry' element = {<AdminProductbyindustry/>}/>
        <Route path='/admin/productbyindustry/add' element = {<AddProductbyindustry/>}/>
        <Route path='/admin/productbyindustry/update/:id' element = {<UpdateProductbyindustry/>}/>

        <Route path='/admin/customer' element = {<AdminCustomer/>}/>
        <Route path='/admin/customer/add' element = {<AddCustomer/>}/>
        <Route path='/admin/customer/update/:id' element = {<UpdateCustomer/>}/>

        <Route path='/admin/team' element = {<AdminTeam/>}/>
        <Route path='/admin/team/add' element = {<AddTeam/>}/>
        <Route path='/admin/team/update/:id' element = {<UpdateTeam/>}/>

        <Route path='/admin/contact' element = {<AdminContact/>}/>
        <Route path='/admin/contact/add' element = {<AddContact/>}/>
        <Route path='/admin/contact/update/:id' element = {<UpdateContact/>}/>

        
        
    </Routes>
    
    
    </BrowserRouter>
  )
}

export default Myroutes