import React from 'react'

import { Link } from 'react-router-dom'

const AdminSidebar = ( {menu, carousel, aboutus, brands, productbyindustry, productbygeneric, customers, contactus, team} ) => {

  // const navigate = useNavigate()

  // const handleSignout = () => {
  //   signout()
  //   .then(()=>{
  //     localStorage.removeItem('jwt')
  //     navigate('/')
  //     })
  //   }
  return (
   <>
   <div className="d-flex flex-column flex-shrink-0 p-3 bg-body-tertiary">
    <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
      {/* <svg className="bi pe-none me-2" width="40" height="32"><use xlink:to="#bootstrap"/></svg> */}
      
      <span className="fs-4"><i className='bi bi-speedometer2 me-2'></i>Admin Dashboard</span>
      
    </Link>
    <hr/>
    <ul className="nav nav-pills flex-column mb-auto">
      <li>
       { menu ?
        <Link to="/admin/menu" className="nav-link text-white active">
          
          <i className='bi bi-grid me-2'></i>
          Menu
        </Link>
        :
        <Link to="/admin/menu" className="nav-link link-dark">
          {/* <svg className="bi pe-none me-2" width="16" height="16"><use xlink:to="#grid"/></svg> */}
          <i className='bi bi-grid me-2'></i>
          Menu
        </Link>
      }
      </li>
      <li>
       { aboutus ?
        <Link to="/admin/about" className="nav-link text-white active">
          
          <i className='bi bi-grid me-2'></i>
          Aboutus
        </Link>
        :
        <Link to="/admin/about" className="nav-link link-dark">
          {/* <svg className="bi pe-none me-2" width="16" height="16"><use xlink:to="#grid"/></svg> */}
          <i className='bi bi-grid me-2'></i>
          Aboutus
        </Link>
      }
      </li>
      <li>
        {
          carousel ?
          <Link to="/admin/carousel" className="nav-link text-white active">
          
          <i className='bi bi-grid me-2'></i>
          Slider
        </Link>
        :
        <Link to="/admin/carousel" className="nav-link link-dark">
         
          <i className='bi bi-grid me-2'></i>
          Slider
        </Link>

        }
        
      </li>
      <li>
        {
          brands ?
          <Link to="/admin/brand" className="nav-link text-white active">
         <i className='bi bi-grid me-2'></i>
          Brands
        </Link>
        :
        <Link to="/admin/brand" className="nav-link link-dark">
          <i className='bi bi-grid me-2'></i>
          Brands
        </Link>
        }
        
      </li>
      <li>
        {
          productbyindustry ?
          <Link to="/admin/productbyindustry" className="nav-link text-white active">
          
          <i className='bi bi-grid me-2'></i>
          Productbyindustry
        </Link>
        :
        <Link to="/admin/productbyindustry" className="nav-link link-dark">
         
          <i className='bi bi-grid me-2'></i>
          Productbyindustry
        </Link>

        }
        
      </li>
      
     
      <li>
        {
          productbygeneric?
          <Link to="/admin/productbygeneric" className="nav-link text-white active">
          <i className='bi bi-grid me-2'></i>
          Productbygeneric
        </Link>
        :
        <Link to="/admin/productbygeneric" className="nav-link link-dark">
          <i className='bi bi-grid me-2'></i>
          Productbygeneric
        </Link>
        }
        
      </li>
      <li>
        {
          customers?
          <Link to="/admin/customer" className="nav-link text-white active">
        
          <i className='bi bi-person me-2'></i>
          Customers
        </Link>
        :
        <Link to="/admin/customer" className="nav-link link-dark">
        
          <i className='bi bi-person me-2'></i>
          Customers
        </Link>
        }
        
      </li>
      <li>
        {
          contactus?
          <Link to="/admin/contact" className="nav-link text-white active">
             <i className='bi bi-person me-2'></i>
          Contactus
        </Link>
        :
        <Link to="/admin/contact" className="nav-link link-dark">
           <i className='bi bi-person me-2'></i>
          Contactus
        </Link>
        }
        
      </li>
      <li>
        {
          team?
          <Link to="/admin/team" className="nav-link text-white active">
             <i className='bi bi-person me-2'></i>
          Team
        </Link>
        :
        <Link to="/admin/team" className="nav-link link-dark">
           <i className='bi bi-person me-2'></i>
        Team
      </Link>

        }
       
      </li>
    </ul>
    <hr/>
    <div className="dropdown">
      <Link to="#" className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2"/>
        <strong>mdo</strong>
      </Link>
      <ul className="dropdown-menu text-small shadow">
        
        <li><Link className="dropdown-item" to="#">Profile</Link></li>
        <li><hr className="dropdown-divider"/></li>
        <li><span className="dropdown-item">Sign out</span></li>
      </ul>
    </div>
  </div>
   


  
   
   </>
  )
}

export default AdminSidebar