import React, { useEffect, useRef, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { addTeam } from '../../../../api/teamapi';

const AddTeam = () => {
    const [team, setTeam] = useState({
        team_title: '',
        team_image: '',
        team_description: '',
        error:'',
        success:false,
        formdata: ''
    })
    let file_ref = useRef()
    // const {token} = isAuthenticated()
    const {team_title,team_description,error,success,formdata} = team

    useEffect(()=>{
        setTeam({...team, formdata: new FormData})
    },[])

    const handleChange = title => e => {
        let value
        if(title === 'team_image'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
        }
        setTeam({...team, [title]: value})
        formdata.set(title, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // addBrand(formdata, token)
        addTeam(formdata)
        .then(data=>{
            if(data.error){
                setTeam({...team, error:data.error})
            }
            else{
                setTeam({success:true, team_name: '', team_link: '', formdata: new FormData, error:''})
                file_ref.current.value = ''
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Team Added Successfully</div>
           
        }
    }

  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar team/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Add New Team.</h2>
                {showError()}
                {showSuccess()}
                        
                <form>
                    <label htmlFor='team_name'>Team Title</label>
                    <input type='text' className='form-control mb-1' id='team_title' onChange={handleChange('team_title')} value={team_title}/>
                    <label htmlFor='team_image'>Team Image</label>
                    <input type='file' className='form-control mb-1' id='team_image' onChange={handleChange('team_image')}/>
                    <label htmlFor='team_description'>Team Description</label>
                    <textarea className='form-control mb-1' rows={5} id='team_description' onChange={handleChange('team_description')} value={team_description}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Add Team</button>
                </form>
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default AddTeam