import { API } from "../config";

export const getAllAbouts = () =>{
    return fetch (`${API}/getallabouts`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

export const addAbout = (about, token) => {
    return fetch(`${API}/addabout`,{
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        body:about
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to get about details
export const getAboutDetails = (id) => {
    return fetch(`${API}/aboutdetails/${id}`)
    .then(res=>res.json())
    .catch(err=>console.log(err))

}

//to update about
export const updateAbout = (id, about, token) => {
    return fetch(`${API}/updateabout/${id}`,{
        method: "PUT",
        headers:{
           
            "Authorization" : `Bearer ${token}`
        },
        body: about
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to delete about
export const deleteAbout = (id, token) => {
    return fetch(`${API}/deleteabout/${id}`,{
        method: "DELETE",
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`
        }
})
.then(res=>res.json())
.catch(err=>console.log(err))
}
