import React, { useEffect, useRef, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { addAbout } from '../../../../api/aboutapi';

const AddAbout = () => {
    const [about, setAbout] = useState({
        about_title: '',
        about_image: '',
        about_description: '',
        error:'',
        success:false,
        formdata: ''
    })
    let file_ref = useRef()

    // const {token} = isAuthenticated()
    const {about_title,about_description,error,success,formdata} = about

    useEffect(()=>{
        setAbout({...about, formdata: new FormData})
    },[])

    const handleChange = title => e => {
        let value
        if(title === 'about_image'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
        }
        setAbout({...about, [title]: value})
        formdata.set(title, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // addAbout(formdata, token)
        addAbout(formdata)
        .then(data=>{
            if(data.error){
                setAbout({...about, error:data.error, success: false})
            }
            else{
                setAbout({success:true, about_title: '', about_description: '', formdata: new FormData, error:''})
                file_ref.current.value = ''
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>About Added Successfully.</div>
        }
    }

  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar aboutus/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Add New About.</h2>
                {showError()}
                {showSuccess()}
                        
                <form>
                    <label htmlFor='about_title'>About Title</label>
                    <input type='text' className='form-control mb-1' id='about-title' onChange={handleChange('about_title')} value={about_title}/>
                    <label htmlFor='about_image'>About Image</label>
                    <input type='file' className='form-control mb-1' id='about_image' onChange={handleChange('about_image')} ref={file_ref}/>
                    <label htmlFor='about_description'>About Description</label>
                    <textarea className='form-control mb-1' rows={5} id='about_description' onChange={handleChange('about_description')} value={about_description}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Add About</button>
                </form>
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default AddAbout