import React, { useEffect, useRef, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { getTeamDetails, updateTeam } from '../../../../api/teamapi';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { API } from '../../../../config';

const UpdateTeam = () => {
    const [team, setTeam] = useState({
        team_title: '',
        team_image: '',
        team_description: '',
        error:'',
        success:false,
        formdata: new FormData
    })
    let file_ref = useRef()

    const {id} = useParams()
    // const {token} = isAuthenticated()
    const {team_image, team_title,team_description,error,success,formdata} = team

    useEffect(()=>{
        getTeamDetails(id)
        .then(data=>{
            if(data.error){
                console.log(data.error)
            }
            else{
                //setAbout(data)
                setTeam({...team, ...data, formdata:new FormData})
            }
        })
        
    },[success])


    const handleChange = title => e => {
        let value
        if(title === 'team_image'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
            setTeam({...team, [title]: value})
        }
       
        formdata.set(title, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // updateTeam(formdata, token)
        updateTeam(id, formdata)
        .then(data=>{
            if(data.error){
                setTeam({...team, error:data.error, success: false})
            }
            else{
                setTeam({success:true, error:''})
               
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Team Updated Successfully</div>
           
        }
    }

  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar team/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Update New Team.</h2>
                {showError()}
                {showSuccess()}

                <div className='row'>
                    <div className='col-6'>
                        <img src={`${API}/${team_image}`} className='w-100'/>
                    </div>
                    <div className='col-6'>
                    <form>
                    <label htmlFor='team_name'>Team Title</label>
                    <input type='text' className='form-control mb-1' id='team_title' onChange={handleChange('team_title')} value={team_title}/>
                    <label htmlFor='team_image'>Team Image</label>
                    <input type='file' className='form-control mb-1' id='team_image' onChange={handleChange('team_image')} ref={file_ref}/>
                    <label htmlFor='team_description'>Team Description</label>
                    <textarea className='form-control mb-1' rows={5} id='team_description' onChange={handleChange('team_description')} value={team_description}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Update Team</button>

                    <Link to = '/admin/team'>Go back</Link>
                </form>
                    </div>
                </div>
                        
                
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default UpdateTeam