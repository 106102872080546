import React from 'react'
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>

      <div className="container">
        <footer className="row">


          {/* <div className="col-3 mb-3">
      <h5>Our Brands</h5>
      <ul className="nav flex-column" style={{height:'25vh'}}>
        <li className="nav-item mb-2"><Link to="https://www.clariant.com/en/Corporate" className="nav-link p-0 text-dark">ClariantSpecialty
Chemicals</Link></li>
        <li className="nav-item mb-2"><Link to="https://www.cl
ariant.com/en/
Corporate" className="nav-link p-0 text-dark">Indofil
Industries
Limited</Link></li>
        <li className="nav-item mb-2"><Link to="https://www.cl
ariant.com/en/
Corporate" className="nav-link p-0 text-dark">Atul
        Limited</Link></li>
        <li className="nav-item mb-2"><Link to="https://www.cl
ariant.com/en/
Corporate" className="nav-link p-0 text-dark">Atul
Limited</Link></li>
        <li className="nav-item mb-2"><Link to="https://www.cl
ariant.com/en/
Corporate" className="nav-link p-0 text-dark">About</Link></li>
      </ul>
    </div> */}

          <div className="col-md-4 mb-3">
            <div className='text-secondary'><h5>Quick Links</h5></div>
            <ul className="nav flex-column" style={{ width: '150px' }}>
              {/* <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-dark">Home</Link></li> */}
              <li className="nav-item mb-2"><Link to="/aboutus" className="nav-link p-0 text-dark">About Us</Link></li>
              <li className="nav-item mb-2"><Link to="/team" className="nav-link p-0 text-dark">Our Team</Link></li>
              <li className="nav-item mb-2"><Link to="/brands" className="nav-link p-0 text-dark">Our Brands</Link></li>
              <li className="nav-item mb-2"><Link to="/product" className="nav-link p-0 text-dark">Our Products</Link></li>
              {/* <li className="nav-item mb-2"><Link to="/contact" className="nav-link p-0 text-dark">Contact Us</Link></li> */}
            </ul>
          </div>

          <div className="col-md-4 mb-3">
            <div className='text-secondary'><h5>Contact Us</h5></div>
            <ul className="nav flex-column" >
              <li className="nav-item mb-2"><i className="bi bi-geo-alt-fill m-1"></i>Teku,Kathmandu-12</li>
              <li className="nav-item mb-2"><i className="bi bi-telephone-fill m-1"></i>01-5340944</li>
              {/* <li className="nav-item mb-2"><i className="bi bi-envelope-fill m-3"></i>kiran@dakali.com.np</li> */}
              <li className="nav-item mb-2"><i className="bi bi-envelope-fill m-1"></i>info@dakali.com.np</li>
              <li className="nav-item mb-2"><i className="bi bi-globe-europe-africa m-1"></i>https://dakali.com.np</li>
            </ul>

          </div>

          <div className="col-md-4 mb-3">
            <Link className="text-body-secondary text-decoration-none" to="/aboutus"><h5>About Us</h5></Link>
            {/* <Link to='#'><div className='text-secondary'><h5>About Us</h5></div></Link> */}
            <ul className="nav flex-column" >

              <li className="nav-item mb-2">Dakali Industrial Marketing Pvt. Ltd. proudly carries forward the legacy of a family business, which
                began with a humble shop in Bangemuda, Kathmandu. For years, the family served the local community,
                building a reputation for.....</li>
            </ul>
          </div>

          {/* <div className="col mb-6">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.6409875509416!2d85.3000028745354!3d27.69748892592786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb18507aaaaaab%3A0x8589e9d552290806!2sDakali%20Industrial%20Marketing%20Pte.%20Ltd.!5e0!3m2!1sen!2snp!4v1726895753929!5m2!1sen!2snp" 
    width="500" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div> */}
        </footer>
      </div>
      <div className='container-fluid bg-body-secondary'>
          <div className="d-flex flex-column flex-sm-row justify-content-center py-2 my-2  border-0">
            <p>&copy; 2024 Dakali Industrial Marketing Pte. Ltd. All rights reserved.</p>
          </div>
          </div>


    </>
  )
}

export default Footer