import { API } from "../config";

export const getAllCarousels = () =>{
    return fetch (`${API}/getallcarousels`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

export const addCarousel = (carousel, token) => {
    return fetch(`${API}/addcarousel`,{
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        body:carousel
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to get carousel details
export const getCarouselDetails = (id) => {
    return fetch(`${API}/carouseldetails/${id}`)
    .then(res=>res.json())
    .catch(err=>console.log(err))

}

//to update carousel
export const updateCarousel = (id, carousel, token) => {
    return fetch(`${API}/updatecarousel/${id}`,{
        method: "PUT",
        headers:{
           
            "Authorization" : `Bearer ${token}`
        },
        body: carousel
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to delete carousel
export const deleteCarousel = (id, token) => {
    return fetch(`${API}/deletecarousel/${id}`,{
        method: "DELETE",
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`
        }
})
.then(res=>res.json())
.catch(err=>console.log(err))
}