import React, { useEffect, useRef, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { getCarouselDetails, updateCarousel } from '../../../../api/carouselapi';
import { useParams } from 'react-router-dom';
import { API } from '../../../../config';
import { Link } from 'react-router-dom';

const UpdateCarousel = () => {
    const [carousel, setCarousel] = useState({
        carousel_title: '',
        carousel_image: '',
        carousel_description: '',
        error:'',
        success:false,
        formdata: new FormData
    })
    let file_ref = useRef()

    const {id} = useParams()

    // const {token} = isAuthenticated()
    const {carousel_image, carousel_title,carousel_description,error,success,formdata} = carousel

    useEffect(()=>{
        getCarouselDetails(id)
        .then(data=>{
            if(data.error){
                console.log(data.error)
            }
            else{
                //setAbout(data)
                setCarousel({...carousel, ...data, formdata: new FormData})
            }
        })
        
    },[success])

    const handleChange = title => e => {
        let value
        if(title === 'carousel_image'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
            setCarousel({...carousel, [title]: value})
        }
        
        formdata.set(title, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // updateCarousel(formdata, token)
        updateCarousel(id, formdata)
        .then(data=>{
            if(data.error){
                setCarousel({...carousel, error:data.error, success: false})
            }
            else{
                setCarousel({success:true, error:''})
               
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Carousel Updated Successfully.</div>
        }
    }

  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar carousel/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Update New Carousel.</h2>
                {showError()}
                {showSuccess()}
                <div className='row'>
                    <div className='col-6'>
                    <img src={`${API}/${carousel_image}`} className='w-100'/>
                    </div>
                    <div className='col-6'>
                    <form>
                    <label htmlFor='carousel_title'>Carousel Title</label>
                    <input type='text' className='form-control mb-1' id='carousel-title' onChange={handleChange('carousel_title')} value={carousel_title}/>
                    <label htmlFor='carousel_image'>Carousel Image</label>
                    <input type='file' className='form-control mb-1' id='carousel_image' onChange={handleChange('carousel_image')} ref={file_ref}/>
                    <label htmlFor='carousel_description'>Carousel Description</label>
                    <textarea className='form-control mb-1' rows={5} id='carousel_description' onChange={handleChange('carousel_description')} value={carousel_description}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Update Carousel</button>

                    <Link to = '/admin/carousel'>Go back</Link>
                </form>
                    </div>
                </div>
                        
                
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default UpdateCarousel