import React, { useEffect, useRef, useState } from 'react'
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import { useParams } from 'react-router-dom'
import { getAboutDetails, updateAbout } from '../../../../api/aboutapi'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { Link } from 'react-router-dom'
import { API } from '../../../../config'

const UpdateAbout = () => {
    const [about, setAbout] = useState({
        about_title: '',
        about_image: '',
        about_description: '',
        error:'',
        success:false,
        formdata: new FormData
    })
    let file_ref = useRef()

    const {id} = useParams()

    // const {token} = isAuthenticated()
    const {about_image, about_title,about_description,error,success,formdata} = about

    useEffect(()=>{
        // setAbout({...about, formdata: new FormData})
        getAboutDetails(id)
        .then(data=>{
            if(data.error){
                console.log(data.error)
            }
            else{
                //setAbout(data)
                setAbout({...about, ...data, formdata:new FormData})
            }
        })
        
    },[success])

    const handleChange = title => e => {
        let value
        if(title === 'about_image'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
        
        setAbout({...about, [title]: value})
    }
        formdata.set(title, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // addAbout(formdata, token)
        updateAbout(id, formdata)
        .then(data=>{
            if(data.error){
                setAbout({...about, error:data.error, success: false})
            }
            else{
                setAbout({success:true, error:''})
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>About Updated Successfully.</div>
        }
    }


  return (
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar aboutus/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Update About.</h2>
                {showError()}
                {showSuccess()}
                <div className='row'>
                    <div className='col-6'>
                        <img src={`${API}/${about_image}`} className='w-100'/>
                    </div>
                    <div className='col-6'>
                    <form>
                    <label htmlFor='about_title'>About Title</label>
                    <input type='text' className='form-control mb-1' id='about-title' onChange={handleChange('about_title')} value={about_title}/>
                    <label htmlFor='about_image'>About Image</label>
                    <input type='file' className='form-control mb-1' id='about_image' onChange={handleChange('about_image')} ref={file_ref}/>
                    <label htmlFor='about_description'>About Description</label>
                    <textarea className='form-control mb-1' rows={5} id='about_description' onChange={handleChange('about_description')} value={about_description}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Update About</button>

                    <Link to = '/admin/about'>Go back</Link>
                </form>
                    </div>
                </div>
               
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default UpdateAbout
