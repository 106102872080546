import React, { useEffect, useRef, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { addCarousel } from '../../../../api/carouselapi';

const AddCarousel = () => {
    const [carousel, setCarousel] = useState({
        carousel_title: '',
        carousel_image: '',
        carousel_description: '',
        error:'',
        success:false,
        formdata: ''
    })
    let file_ref = useRef()
    // const {token} = isAuthenticated()
    const {carousel_title,carousel_description,error,success,formdata} = carousel

    useEffect(()=>{
        setCarousel({...carousel, formdata: new FormData})
    },[])

    const handleChange = title => e => {
        let value
        if(title === 'carousel_image'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
        }
        setCarousel({...carousel, [title]: value})
        formdata.set(title, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // addCarousel(formdata, token)
        addCarousel(formdata)
        .then(data=>{
            if(data.error){
                setCarousel({...carousel, error:data.error})
            }
            else{
                setCarousel({success:true, carousel_title: '', carousel_description: '', formdata: new FormData, error:''})
                file_ref.current.value = ''
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Carousel Added Successfully.</div>
        }
    }

  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar carousel/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Add New Carousel.</h2>
                {showError()}
                {showSuccess()}
                        
                <form>
                    <label htmlFor='carousel_title'>Carousel Title</label>
                    <input type='text' className='form-control mb-1' id='carousel-title' onChange={handleChange('carousel_title')} value={carousel_title}/>
                    <label htmlFor='carousel_image'>Carousel Image</label>
                    <input type='file' className='form-control mb-1' id='carousel_image' onChange={handleChange('carousel_image')}/>
                    <label htmlFor='carousel_description'>Carousel Description</label>
                    <textarea className='form-control mb-1' rows={5} id='carousel_description' onChange={handleChange('carousel_description')} value={carousel_description}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Add Carousel</button>
                </form>
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default AddCarousel